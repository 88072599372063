// disable routing rules as the below routes are not used in app code for routing, just validation/transformation
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable shipt/no-hardcoded-routes */
import BugsnagPerformance, {
  DefaultRoutingProvider,
} from '@bugsnag/browser-performance'
import { isOnServer } from '@shared/constants/util'
import { dynamicRoutes } from '@shared/constants/pathnames'

const urlsToIgnore = [
  /\.criteo\.com/,
  /\.bugsnag\.com/,
  /\.pinterest\.com/,
  /\.mouseflow\.com/,
  /\.clarity\.ms/,
]

const urlsToIgnoreForSettling = [
  /\.shipt\.com\/pulsar\//, // analytics calls
  /\/aristotle\/v2\/track/, // experiment track calls
]

const routePatterns: Record<string, RegExp> = {}
const routes = [...Object.keys(dynamicRoutes), '/stores/[id]', '/cities/[name]']
routes.forEach((route) => {
  const regexString = route
    .replace(/\[\.\.\.[a-zA-Z0-9_-]+\]/g, '(.+)')
    .replace(/\[([a-zA-Z0-9_-]+)\]/g, '([^/]+)')
  routePatterns[route] = new RegExp(`^${regexString}$`)
})

export const routeMatcher = (url: Pick<URL, 'pathname'>) => {
  for (const pattern in routePatterns) {
    if (routePatterns[pattern]?.test(url.pathname)) {
      return pattern
    }
  }
  return url.pathname
}

// Chrome/96 is related to persistent bot activity - https://app.clickup.com/t/86b477wcz
// MerryMaker is a good bot, monitoring shipt.com for security purposes - https://app.clickup.com/t/86b4dvf9p
const userAgentsToIgnore = ['Chrome/96', 'MerryMaker']

if (!isOnServer()) {
  const disableRumForSession =
    'userAgent' in navigator &&
    userAgentsToIgnore.some((ua) => navigator.userAgent.includes(ua))
  const routingProvider = new DefaultRoutingProvider(routeMatcher)
  if (!disableRumForSession) {
    BugsnagPerformance.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion:
        process.env.NEXT_PUBLIC_VERSION_TAG || // set app version to tag if present
        process.env.NEXT_PUBLIC_COMMIT_SHA || // otherwise use commit sha
        'local',
      releaseStage: process.env.NEXT_PUBLIC_ENV,
      enabledReleaseStages: ['production'],
      samplingProbability: 0.06,
      settleIgnoreUrls: [...urlsToIgnore, ...urlsToIgnoreForSettling],
      networkRequestCallback: (requestInfo) => {
        if (
          urlsToIgnore.some(
            (url) => requestInfo.url && url.test(requestInfo.url)
          )
        ) {
          return null
        }

        return requestInfo
      },
      routingProvider,
    })
  }
}
