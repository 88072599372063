import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { createContext, useContext, type ReactNode } from 'react'
import { RetailerContextProvider } from '@/features/shared/context/RetailerContext'

const defaultStoreContextValue = {
  zip: '',
  user_id: 0,
  store_id: null,
  metro_id: null,
  store_location_id: null,
}

const StoreContext = createContext<StoreParams>(defaultStoreContextValue)

type Props = { children: ReactNode; storeParams: StoreParams }

// ultimately, we will deprecate this provider
export const GlobalStoreContextProvider = ({
  children,
  storeParams,
}: Props) => {
  return (
    <RetailerContextProvider globalStoreParams={storeParams}>
      <StoreContext.Provider value={storeParams}>
        {children}
      </StoreContext.Provider>
    </RetailerContextProvider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
