import { useQueryMembershipPlan } from '@/features/account/services/MembershipPlan/queries'
import { formatDate } from '@/features/shared/utils/date'
import { getSubscriptionInformation } from '@/features/account/components/Membership/utils'
import { differenceInDays } from 'date-fns/differenceInDays'
import {
  SubscriptionId,
  MembershipInterval,
  MembershipStatus,
  type PlanName,
  MembershipPlanType,
} from '@/features/account/services/MembershipPlan/types'
import { toMembershipPlan } from '@/features/account/services/MembershipPlan/utils'
import { useQuerySubscriptionPlans } from '@/features/account/services/SubscriptionAux/queries'

export const useMembership = () => {
  const { data: subscriptionResponse, isFetched: hasMembershipFetched } =
    useQueryMembershipPlan()
  const membership = toMembershipPlan(subscriptionResponse)
  const { data: upgradePlans } = useQuerySubscriptionPlans({
    planType: 'upgrade',
  })

  const {
    cancelAtPeriodEnd,
    renewalDate,
    intervalCount,
    refundable,
    status,
    trialEnd,
  } = membership

  const startDateObj = new Date(membership.startDate)
  const trialEndDateObj = new Date(trialEnd)

  const { planType, price, interval } = getSubscriptionInformation(membership)
  const startDate = formatDate(startDateObj, 'MMM d, yyyy')
  const startMonth = formatDate(startDateObj, 'MMMM')
  const endDate = formatDate(new Date(renewalDate), 'MMM d, yyyy')
  const trialEndDate = formatDate(trialEndDateObj, 'MMM d, yyyy')
  const isRenewing = !cancelAtPeriodEnd

  const isTrial = status === MembershipStatus.trialing
  const isActive = status === MembershipStatus.active
  const isVisa = membership.id === SubscriptionId.VISA_MONTHLY
  const isStudent = membership.id === SubscriptionId.STUDENT_MONTHLY
  const isSnapEBT = membership.id === SubscriptionId.SNAP_EBT_MONTHLY
  const isCircle360 = membership.type === MembershipPlanType.CIRCLE_360
  const planName: PlanName = isStudent
    ? 'Student'
    : isSnapEBT
    ? 'SNAP EBT'
    : planType
  const isFirstThirtyDaysOfPlan =
    differenceInDays(new Date(), startDateObj) <= 30

  const canUpgrade =
    interval !== MembershipInterval.year &&
    intervalCount === 1 &&
    isRenewing &&
    !isTrial &&
    !isStudent &&
    !isSnapEBT &&
    Boolean(upgradePlans?.some((plan) => plan.plan_type === 'upgrade'))
  const isRefundable = Boolean(refundable)

  const trialDays = isTrial
    ? differenceInDays(trialEndDateObj, startDateObj)
    : 0

  return {
    ...membership,
    hasMembershipFetched,
    planType,
    planName,
    status,
    isTrial,
    isActive,
    isVisa,
    isStudent,
    isSnapEBT,
    isCircle360,
    startDate,
    startMonth,
    endDate,
    trialEndDate,
    trialDays,
    isRenewing,
    canUpgrade,
    upgradePlans,
    price,
    interval,
    isRefundable,
    isFirstThirtyDaysOfPlan,
  }
}
