import { createContext, type ReactNode } from 'react'
import {
  useLegacyStoreParams,
  type StoreParams,
} from '@/features/shared/utils/dataFetching/storeParams'

export const RetailerContext = createContext<StoreParams | undefined>(undefined)

export const RetailerContextProvider = ({
  children,
  globalStoreParams,
}: {
  children: ReactNode
  globalStoreParams?: StoreParams
}) => {
  const userStoreParams = useLegacyStoreParams()

  return (
    <RetailerContext.Provider value={globalStoreParams ?? userStoreParams}>
      {children}
    </RetailerContext.Provider>
  )
}
