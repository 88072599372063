import { useContext, useMemo } from 'react'
import { getUser, getUserZip } from '@/features/account/services/User/utils'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import {
  getGuestStoreParams,
  getGuestStoreParamsSSR,
} from '@/features/shared/utils/dataFetching/guestStoreParams'
import { type User } from '@/features/account/services/User/types'
import { type GetServerSidePropsContext } from 'next'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { getCookie } from '@/features/shared/utils/cookies'
import { GuestDataStore } from '@/features/shared/constants/global'
import { RetailerContext } from '@/features/shared/context/RetailerContext'
import { useQueryUser } from '@/features/account/services/User/queries'

export const getStoreParams = (user: User): StoreParams => {
  const { id, metro_id, store_id, store_location_id } = user
  return {
    user_id: id,
    metro_id,
    store_id,
    store_location_id,
    zip: getUserZip(user),
  }
}

export const useStoreParams = (): StoreParams => {
  const value = useContext(RetailerContext)
  // fallback if we are not within store context
  const params = useLegacyStoreParams()

  if (!value) {
    return params
  }
  return value
}

export const useLegacyStoreParams = (): StoreParams => {
  const { data: user } = useQueryUser({ notifyOnChangeProps: ['data'] })
  const { guestStore, guestAddress } = useGuestUserContext() ?? {}
  const { user_id, metro_id, store_id, store_location_id, zip } = user?.id
    ? getStoreParams(user)
    : getGuestStoreParams({ guestStore, guestAddress })
  return useMemo(
    () => ({
      zip,
      user_id,
      metro_id,
      store_id,
      store_location_id,
    }),
    [user_id, metro_id, store_id, store_location_id, zip]
  )
}
export type StoreParams = {
  /**
   * @deprecated user_id should only be accessed from user hooks e.g. useUser, useUserId, etc
   */
  user_id: number
  /**
   * @deprecated zip should only be accessed from user hooks e.g. useUser, useUserZip, etc
   */
  zip: string
  metro_id: number | null
  store_id: number | null
  store_location_id: number | null
}

export type GlobalStoreParams = {
  metro_id: number
  store_id: number
  store_location_id: number
}

export type StoreParamsAsKey = {
  storeParams: StoreParams
}

export const getStoreParamsSSR = (
  ssrContext: GetServerSidePropsContext,
  user: User | undefined
) => {
  if (user?.id) return getStoreParams(user)
  return getGuestStoreParamsSSR(ssrContext).guestStoreParams
}

export const getStoreParamsClient = () => {
  const user = getUser(getQueryClient())
  return user?.id
    ? getStoreParams(user)
    : getGuestStoreParams({
        guestStore: getCookie(GuestDataStore.STORE),
        guestAddress: getCookie(GuestDataStore.ADDRESS),
      })
}
